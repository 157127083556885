<template>
    <div>
      <v-card class="mb-6 ml-6 mt-6 mr-2">
        <v-card-title> Liste des Utilisateurs </v-card-title>
        <v-row>   <v-col cols="6" sm="6" md="6"> <v-text-field
          style="padding:15px"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          @clear="resetSearch"
         
        ></v-text-field> </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-btn
               @click="reSearchByKeyWord()"
                color="primary"
                class="me-3 mt-3"
                style="background-color: #A8FE39; color:#ffffff"
              >
              Recherche
              </v-btn>
              <v-btn
               @click="resetSearch()"
                class="me-3 mt-3"
                style="background-color: #f73131; color:#ffffff"
              >
              Clear
              </v-btn>
        </v-col>
      </v-row>
        <v-data-table
        :loading="isLoadingTables"
        loading-text="Loading..."
        hide-default-footer
          :headers="headers"
          :items="users"
          class="elevation-1"
          item-key="uid"
          
        >
        <template v-slot:item.Delete="{ item }">
          <v-icon @click="DeleteAction(item)">
            {{ icons.mdiDeleteOutline }}
          </v-icon>
        </template>
          <template v-slot:item.subscribeId="{ item }">
            <v-icon v-if="item.subscribeId != null" color="#A8FE39" >
              {{ icons.mdiCheckDecagram   }}
            </v-icon>
            <v-icon v-else color="#ff0007"  >
              {{ icons.mdiAlertOctagramOutline  }}
            </v-icon>
       
          </template>
          
          <template v-slot:item.Subscribe="{ item }">


            <template v-if="item.subscribeId != null">
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-icon @click="AddUsertoSubscribe(item)" color="#16b1ff" v-bind="attrs" v-on="on" style="cursor: pointer;">
                    {{ icons.mdiAccountMultiplePlus  }}
              </v-icon
                >
              </template>
              <span style="cursor: pointer;">Ajouter des utilisateurs a cette Abonnement</span>
            </v-tooltip>
          </template>

          <template v-else>
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-icon @click="AddNewSubscribe(item)" color="#16b1ff" v-bind="attrs" v-on="on" style="cursor: pointer; ">
                    {{ icons.mdiNewBox  }}
              </v-icon
                >
              </template>
              <span style="cursor: pointer;"> Crée Nouvel Abonnement</span>
            </v-tooltip>
          </template>
          </template>
        </v-data-table>
        <v-row>
          <v-col cols="2" sm="2" md="2"></v-col>
          <v-col cols="8" sm="8" md="8">
        <v-pagination
          v-if="showPagination"
          v-model="page"
          :length="pageCount"
          :total-visible="7"
        ></v-pagination> </v-col> 
        <v-col style="margin-top:15px" cols="2" sm="2" md="2">Total d'utilisateurs: {{totale}}</v-col>
      </v-row>
      </v-card>
      <v-row justify="center">
        <!-- OLD SUBSCRIBE -->
        <v-dialog v-model="dialogOldSubscribe" max-width="600">
          <v-card color="text-center">
            <v-card-text class="d-flex flex-column justify-center align-center">
              <v-avatar color="primary" class="mt-10" icon size="50">
                <v-icon size="2rem" color="white">
                  {{ icons.mdiAlertCircleOutline }}
                </v-icon>
              </v-avatar>
            </v-card-text>
            <v-card-text
              ><h2 style="color: black;">
                Pouvez-vous saisir l'e-mail de l'utilisateur ?  </br>   
              </h2>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
    v-model="newSubscritionUser"
    clearable
    label="e-mail de l'utilisateur"
    
  >
   
  </v-text-field>
          </v-col>
            </v-card-text>
            <v-card-text>
              <v-btn
                @click="AddOldSubscribeToUser()"
                color="primary"
                class="mt-4"
                style="background-color: #A8FE39; color: aliceblue;"
              >
                Confirmer
              </v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>
         <!-- END OLD SUBSCRIBE -->
      </v-row>
      <v-row justify="center">
        <!-- NEW SUBSCRIBE -->
        <v-dialog v-model="dialogNewSubscribe" max-width="600">
          <v-card color="text-center">
            <v-card-text class="d-flex flex-column justify-center align-center">
              <v-avatar color="primary" class="mt-10" icon size="50">
                <v-icon size="2rem" color="white">
                  {{ icons.mdiAlertCircleOutline }}
                </v-icon>
              </v-avatar>
            </v-card-text>
            <v-card-text
              ><h2 style="color: black;">
                Pouvez-vous sélectionner le type d'abonnement à attribuer  </br>
           
                <a color="error" >{{mailUserNewSubscribe}}</a>
              </h2>
              <v-col cols="12" sm="12" md="12">
                <v-select
    v-model="SubscritionType"
    clearable
    label="Type d'abonnement"
    :items="listSubscritionType"
    item-value="id"
    outlined
    item-text="name"
  >
    <template #item="{ item }">
      <div>{{ item.price }} € - {{ item.name }}</div>
    </template>
  </v-select>
          </v-col>
            </v-card-text>
            <v-card-text>
              <v-btn
                @click="AddNewSubscribeToUser()"
                color="primary"
                class="mt-4"
                style="background-color: #A8FE39; color: aliceblue;"
              >
                Confirmer
              </v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>
         <!-- END NEW SUBSCRIBE -->
      </v-row>
      <v-row justify="center">
        <v-dialog v-model="dialogDelete" max-width="600">
          <v-card color="text-center">
            <v-card-text class="d-flex flex-column justify-center align-center">
              <v-avatar color="error" class="mt-10" icon size="50">
                <v-icon size="2rem" color="white">
                  {{ icons.mdiAlertCircleOutline }}
                </v-icon>
              </v-avatar>
            </v-card-text>
            <v-card-text
              ><h2 style="color: black;">
                Etes-vous sûr de vouloir Supprimer l' Abonnement de l'utilisateur </br> <a color="error" >{{this.mailUserDelete}}</a>
             
              </h2>
            </v-card-text>
            <v-card-text>
              <v-btn
                @click="DeleteUserLogin()"
                color="error"
                class="mt-4"
                style="background-color: #ff0000; color: aliceblue;"
              >
                Confirmer
              </v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </template>
  
  <script>
  import moment from "moment";
  import {
    mdiAccountMultiplePlus ,
    mdiNewBox ,
    mdiAlertOctagramOutline ,
    mdiCheckDecagram   ,
    mdiPlus,
    mdiDeleteOutline,
    mdiPencil,
    mdiDatabasePlusOutline,
    mdiAlertCircleOutline,
    mdiAccountKey,
  } from "@mdi/js";
  import axios from "axios";
  import { getAllUserstByRole,SearchByKeyWord } from "@/views/qorani/services/services";
  import Constant from "@/utils/constants";
  import "firebase/auth";
  export default {
    data() {
      return {
        newSubscritionUser: null,
        IdUserNewSubscribe: null,
        mailUserNewSubscribe: null,
        SubscritionType: null,
        listSubscritionType:[],
        Subscritiondetails:[],
        totale: null,
        list:{},
        pageCount: 0,
        page: 1,
        itemsPerPage: 10,
        showPagination:true,
        search: "",
        icons: {
            mdiAccountMultiplePlus ,
            mdiNewBox ,
            mdiAlertOctagramOutline ,
            mdiCheckDecagram   ,
          mdiAlertCircleOutline,
          mdiPlus,
          mdiDeleteOutline,
          mdiPencil,
          mdiDatabasePlusOutline,
          mdiAccountKey,
        },
        users: [],
        mailUserDelete: null,
        subscriptionId:null,
        oldsubscriptionId:null,
        dialogDelete:false,
        isLoadingTables: false,
        idUserSupprimmer: null,
        mailUserSupprimmer: null,
        dialogNewSubscribe: false,
        dialogOldSubscribe: false,
        headers: [
          { text: "Email", value: "email" },
          { text: "Nom", value: "lastName" },
          { text: "Prénom", value: "firstName" },
          {
            text: "Etat d'abonnement",
            value: "subscribeId",
            align: "center",
          },
          {
            text: "Ajouter un Abonnement",
            value: "Subscribe",
            align: "center",
          },
          {
            text: "supprimer l'utilisateur de l'abonnement",
            value: "Delete",
            align: "center",
          },
        ],
      };
    },
    async created() {
        await this.getAllSubscritionType();
      this.isLoadingTables =true
      this.list = await getAllUserstByRole("USER",0);
      this.users =  this.list.userList;
      this.totale = this.list.totalNbrElement;
      this.pageCount =  this.list.nbrTotalPage;
       this.isLoadingTables =false
    },
    watch: {
      async page(newValue) {
        this.isLoadingTables = true;
  
        const responseVerset = await getAllUserstByRole("USER",newValue - 1);
        this.users = responseVerset.userList;
  
        this.isLoadingTables = false;
      },
    },
    methods: {
        async AddOldSubscribeToUser(){
          try {
    const responseSubscription = await axios.get(`${Constant.QORANI}subscription/get/${this.oldsubscriptionId}`);
    this.Subscritiondetails = responseSubscription.data;
  } catch (error) {
    console.error("An error occurred while fetching subscription data:", error);
   
  }
switch (this.Subscritiondetails.premium.id) {
  case 4:
    if (this.Subscritiondetails.listUser.length > 0) {
      alert('cet abonnement a atteint le maximum d utilisateurs');
    } else  {
      this.addUserToSubscription(this.newSubscritionUser, this.oldsubscriptionId);
    }
    break;
  case 14:
  if (this.Subscritiondetails.listUser.length > 1) {
      alert('cet abonnement a atteint le maximum d utilisateurs');
    } else  {
      this.addUserToSubscription(this.newSubscritionUser, this.oldsubscriptionId);
    }
    break;
  case 24:
  if (this.Subscritiondetails.listUser.length > 4) {
      alert('cet abonnement a atteint le maximum d utilisateurs');
    } else  {
      this.addUserToSubscription(this.newSubscritionUser, this.oldsubscriptionId);
    }
    break;
  case 34:
  if (this.Subscritiondetails.listUser.length > 4) {
      alert('cet abonnement a atteint le maximum d utilisateurs');
    } else  {
      this.addUserToSubscription(this.newSubscritionUser, this.oldsubscriptionId);
    }
    break;
  case 44:
  if (this.Subscritiondetails.listUser.length > 1) {
      alert('cet abonnement a atteint le maximum d utilisateurs');
    } else  {
      this.addUserToSubscription(this.newSubscritionUser, this.oldsubscriptionId);
    }
    break;
  case 54:
  if (this.Subscritiondetails.listUser.length > 0) {
      alert('cet abonnement a atteint le maximum d utilisateurs');
    } else  {
      this.addUserToSubscription(this.newSubscritionUser, this.oldsubscriptionId);
    }
    break;
  default:
  alert('Abonnement Invalid ');
}

        },
        async addUserToSubscription(userEmail, subscriptionId) {
    const url = Constant.QORANI + 'subscription/addUser';
    const data = {
      userEmail: userEmail,
      subscriptionId: subscriptionId
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
      });

      // Check if the request was successful
      if (response.ok) {
        this.dialogOldSubscribe = false;
        window.location.reload();
      } else {
        throw new Error('Failed to add user to subscription');
      }
    } catch (error) {
      console.error("An error occurred:", error);
      alert("Une erreur est survenue, veuillez réessayer plus tard. Merci.");
      this.dialogOldSubscribe = false;
    }
  },
        async AddNewSubscribeToUser(){
            const url = Constant.QORANI + 'subscription/add';
    const data = {
        premiumId: this.SubscritionType,
        userId: this.IdUserNewSubscribe
   
    };

    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    });

    try {
        this.dialogNewSubscribe = false;
        window.location.reload();
    } catch (error) {
        alert(
            "Une erreur est survenue , veuillez réessayer plus tard. Merci."
        );
        this.dialogNewSubscribe = false;
    }
        },
      async reSearchByKeyWord(){
        this.users =[]
        this.list=[]
      this.isLoadingTables =true
      this.list = await SearchByKeyWord(this.search);
      this.users =  this.list;
      this.pageCount = 0;
      this.isLoadingTables =false
      },
      async resetSearch(){
        this.search ="",
      this.isLoadingTables =true
      this.list = await getAllUserstByRole("USER",0);
      this.users =  this.list.userList;
      this.pageCount =  this.list.nbrTotalPage;
      this.isLoadingTables =false
      },
   
      async DeleteUserLogin() {
    const url = Constant.QORANI + 'subscription/removeUser';
    const data = {
        userEmail: this.mailUserDelete,
        subscriptionId: this.subscriptionId
    };

    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    });

    try {
        this.dialogDelete = false;
        window.location.reload();
    } catch (error) {
        alert(
            "Une erreur est survenue lors de la suppression du juz, veuillez réessayer plus tard. Merci."
        );
        this.dialogDelete = false;
    }
},
      formatDate(creationDate) {
        return moment(new Date(creationDate.seconds * 1000)).format(
          "YYYY-MM-DD HH:mm"
        );
      },
      DeleteAction(item){
        this.mailUserDelete = item.email;
        this.subscriptionId = item.subscribeId;
        this.dialogDelete = true;
      },
      AddNewSubscribe(item) {
        this.mailUserNewSubscribe= item.email;
        this.IdUserNewSubscribe= item.userId;
        this.dialogNewSubscribe = true;
      },
      AddUsertoSubscribe(item){
        this.dialogOldSubscribe = true;
        this.oldsubscriptionId=item.subscribeId
      },
     async getAllSubscritionType(){
        const response = await axios
    .get(Constant.QORANI + "premium/getall")
    .then((response) => {
      this.listSubscritionType = response.data;
    });
     }
    },
  
  };
  </script>
  